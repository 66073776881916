import cx from 'classnames';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useGlobalConfig } from '../../hooks/configContext';
import { localeToMarket } from '../../lib/locale';
import { Otovo$Locale } from '../../types/otovoweb';
import { useProductPages } from '../Sanity/ProductPages/useProductPages';
import { localizedProductPagePathRoot } from './constants';
import getSortedProductPageLinks from './getSortedProductPageLinks';
import m from './messages';

type Props = {
  includeDomainInUrls: boolean;
};

function useProductPageLinks(includeDomainInUrls: boolean) {
  const locale = useRouter().locale as Otovo$Locale;
  const { STOREFRONT_URL } = useGlobalConfig();

  const productPages = useProductPages();
  const sortedProductPages = getSortedProductPageLinks(productPages || []);

  const productPagesTranslatedRoute = localizedProductPagePathRoot[locale];

  const domain: string = includeDomainInUrls ? STOREFRONT_URL : '';
  const isMultiLanguageMarket = ['be', 'ch'].includes(localeToMarket(locale));

  let pathPrefix = `${domain}/${productPagesTranslatedRoute}`;
  if (isMultiLanguageMarket) {
    pathPrefix = `${domain}/${locale}/${productPagesTranslatedRoute}`;
  }

  return [sortedProductPages, pathPrefix] as const;
}

export function ProductPageMenuLinks({ includeDomainInUrls = false }: Props) {
  const intl = useIntl();
  const [productPages, pathPrefix] = useProductPageLinks(includeDomainInUrls);

  return (
    <>
      {productPages?.length > 0 && (
        <div>
          {intl.formatMessage(m.productPageBurgerMenu)}
          <ul>
            {(productPages || []).map((page) => (
              <li key={page._id}>
                <a
                  href={`${pathPrefix}/${page.slug}/`}
                  className="text-gray-700 block py-2 pl-6 text-lg hover:underline hover:underline-offset-4"
                >
                  {page.burgerMenuTitle}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export function ProductPageNavLinks({ includeDomainInUrls = false }: Props) {
  const { asPath } = useRouter();
  const [productPages, pathPrefix] = useProductPageLinks(includeDomainInUrls);

  return (
    <>
      {(productPages || []).map((page) => (
        <a
          key={page._id}
          href={`${pathPrefix}/${page.slug}/`}
          className={cx(
            'rounded px-3 py-2 text-sm text-grey-90 hover:bg-grey-5 lg:text-base',
            asPath.indexOf(page.slug) > 0 ? 'font-semibold' : 'font-normal',
          )}
        >
          {page.burgerMenuTitle}
        </a>
      ))}
    </>
  );
}
