export const localizedProductPagePathRoot = {
  'de-at': 'produkte',
  'de-ch': 'produkte',
  'de-de': 'produkte',
  'en-gb': 'products',
  'es-es': 'productos',
  'fr-be': 'produits',
  'fr-ch': 'produits',
  'fr-fr': 'produits',
  'it-ch': 'prodotti',
  'it-it': 'prodotti',
  'nb-no': 'produkter',
  'nl-be': 'producten',
  'nl-nl': 'producten',
  'pl-pl': 'produkty',
  'pt-pt': 'productos',
  'pt-br': 'productos',
  'sv-se': 'produkter',
} as const;
