import type { Sanity$ProductPage } from '../../types/sanityTypes';

export default function getSortedProductPageLinks(
  productPages: Array<Sanity$ProductPage>,
) {
  const order = ['pv', 'battery', 'heat_pump', 'ev_charger', 'hems'];
  const sortedList = [...productPages].sort((a, b) => {
    const indexA = order.indexOf(a.productType);
    const indexB = order.indexOf(b.productType);

    if (indexA === -1) return 1; // if a is not in order, move it to the end
    if (indexB === -1) return -1; // if b is not in order, move it to the end

    return indexA - indexB;
  });
  return sortedList;
}
