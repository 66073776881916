import cx from 'classnames';

const MenuButton = ({
  menuIsOpen,
  updateMenuIsOpen,
}: {
  menuIsOpen: boolean;
  updateMenuIsOpen: (previous: boolean) => void;
}) => {
  return (
    <button
      type="button"
      className={cx(
        'rounded-full bg-lilac-20 p-2 text-grey-90 ring-0 ring-grey-90',
        'focus:outline-none focus:ring-2 focus:ring-blue-70 focus:ring-offset-2',
        'active:bg-lilac-40',
      )}
      onClick={() => updateMenuIsOpen(!menuIsOpen)}
    >
      {!menuIsOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 19.5l15-15m-15 0l15 15"
          />
        </svg>
      )}
    </button>
  );
};

export default MenuButton;
