import m from '../components/Navigation/messages';
import { NavbarLink } from '../components/Navigation/types';
import { Otovo$Config } from '../types/otovoweb';

export function getBlogLink(config: Otovo$Config): NavbarLink | null {
  const blogUrl = config.urls?.blogUrl;
  if (blogUrl) {
    return { to: blogUrl, intlKey: m.blogTitle };
  }
  return null;
}
