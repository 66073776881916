import { useRouter } from 'next/router';
import useSWRImmutable from 'swr/immutable';
import { useGlobalConfig } from '../../../hooks/configContext';
import { getSanityClient } from '../../../lib/sanity/sanityClient';
import { logSentryError, logToSentry } from '../../../lib/sentry';
import { Otovo$Locale } from '../../../types/otovoweb';
import { Sanity$CampaignPage } from '../../../types/sanityTypes';
import { getAllLocatorPagesQuery } from './sanityQueries';

type ResponseType = {
  data: Array<Sanity$CampaignPage> | undefined; // It's undefined when data from SWR loads
  error: Error;
};

const fetcher = (url: string) => getSanityClient().fetch(url);

export function useLocatorPages(): ResponseType['data'] {
  const config = useGlobalConfig();
  const { locale } = useRouter() as { locale: Otovo$Locale };

  const { queryString } = getAllLocatorPagesQuery('', config.BU_CONFIG, locale);

  const { data, error }: ResponseType = useSWRImmutable(queryString, fetcher);

  if (error?.name === 'ClientError') {
    // This means something went wrong with the Sanity client
    logSentryError(error);
    return [];
  }
  if (data === null) {
    // This means Sanity returned an unexpected response.
    logToSentry(
      'useLocatorPages: Unexpected response from Sanity, check GROQ query for errors',
      { queryString, config },
    );

    // Some other error, most likely client side (loss of network etc) - fail silently
    return [];
  }
  return data;
}
