import Link from 'next/link';

import { useGlobalConfig } from '../hooks/configContext';
import BusinessUnitLogo, { getLogoOverrides } from './BusinessUnitLogo';

type Props = {
  className?: string;
};

const BusinessUnitLink = ({ className = '' }: Props) => {
  const { STOREFRONT_URL, BU_CONFIG } = useGlobalConfig();
  const { storefrontHref, style, companyName, logoSrc } = getLogoOverrides(
    BU_CONFIG,
    STOREFRONT_URL,
  );

  return (
    <Link className={className} href={storefrontHref}>
      <BusinessUnitLogo
        logoSrc={logoSrc}
        companyName={companyName}
        style={style}
      />
    </Link>
  );
};

export default BusinessUnitLink;
