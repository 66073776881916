import getSanityLocaleDocumentQuery from '../../../lib/getSanityLocaleDocumentQuery';
import { Cloud$BusinessUnitConfig } from '../../../types/cloudApi';
import { Otovo$Locale } from '../../../types/otovoweb';
import { Sanity$DocumentType, Sanity$Query } from '../../../types/sanityTypes';
import { getAccordionFAQsQuery } from '../SanityFAQAccordion/sanityQueries';

export function getAllProductPageSlugsQuery(
  path: string,
  buConfig: Cloud$BusinessUnitConfig,
  locale: Otovo$Locale | undefined,
): Sanity$Query {
  const localeQuery = getSanityLocaleDocumentQuery({ locale, buConfig });
  const { market } = buConfig;
  return {
    identifier: path,
    queryString: /* groq */ `*[_type == 'productPage' && activeFrom <= $now
    ${localeQuery}
    && references("market-${market}")] | order(priority asc, title asc){
      "slug": slug.current,
      "burgerMenuTitle": burgerMenuTitle,
      "productType": productType,
      "noindex": meta.noindex,
      'locale': __i18n_lang,
      _id
    }`,
  };
}

export function productPageQuery(
  type: Sanity$DocumentType,
  slug: string,
  buConfig: Cloud$BusinessUnitConfig,
  locale: Otovo$Locale | undefined,
): Sanity$Query {
  const localeQuery = getSanityLocaleDocumentQuery({ locale, buConfig });
  const { market } = buConfig;
  const query = `*[_type == "${type}"
    && references("market-${market}") && slug.current == "${slug}"
    ${localeQuery}
    &&
    (
      (activeFrom <= $now &&
      (!defined(endDate)) || endDate >= $now)
    )]{
        ...,
        "documentType": _type,
        mainImage{
          ...,
          asset->{
            ...,
            metadata
          }
        },
        mainHardwareType,
        "banner": {
          ...banner,
          "cta": {
            "title": banner.cta.title,
            "slug": banner.cta.route->slug.current,
          }
        },
        "faqItems": ${getAccordionFAQsQuery(buConfig, locale)},
        trustVendor->{
          _type,
          score,
          url,
          vendor,
          shortText,
        },
        "productSEOData": productSEOData->{
          ...,
        },

        // Rows[] can consist of two things: Objects and references (to reusable sections).
        // The code below converts all references into objects, so that the array sent to Web only consists of objects.
        rows[]{
          ...,
          _type == "relatedProductsSection" => {
            ...,
            productPages[]{
              "_type": page->_type,
              "_key": _key,
              "slug": page->slug.current,
              "title": title,
            }
          },
          _type == "productSection" => {
            ...,
            products[]->{
              ...,
            }
          },
          defined(_ref) => @->{
            ...section[0]
        }
      }
      }
`;
  return {
    identifier: slug,
    queryString: /* groq */ query,
  };
}
