import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import { FormattedMessage as T } from 'react-intl';
import { NavigationLink } from './Links';
import MenuButton from './MenuButton';
import SlideOver from './SlideOver';
import { NavbarLink } from './types';

type Props = {
  children: React.ReactNode;
  useLinks: () => Array<NavbarLink>;
};

const NavSlideoverMenu = ({ children, useLinks }: Props) => {
  const links = useLinks();
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useRouter();

  return (
    <div className="ml-4">
      <SlideOver isOpen={isOpen} setOpen={setIsOpen}>
        <ul className="space-y-5 text-xl text-lilac-20">
          {children}

          {links.map((link) => {
            const includeBorder = link.to.includes('partner');
            return (
              <Fragment key={`nav-link-${link.to}`}>
                {includeBorder && <hr className="border-1 border-lilac-1/50" />}
                <li>
                  <NavigationLink
                    href={link.to}
                    locale={locale}
                    nofollow={link.nofollow}
                  >
                    <T {...link.intlKey} />
                  </NavigationLink>
                </li>
              </Fragment>
            );
          })}
        </ul>
      </SlideOver>
      <MenuButton menuIsOpen={isOpen} updateMenuIsOpen={setIsOpen} />
    </div>
  );
};

export default NavSlideoverMenu;
