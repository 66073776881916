import getSanityLocaleDocumentQuery from '../../../lib/getSanityLocaleDocumentQuery';
import { Cloud$BusinessUnitConfig } from '../../../types/cloudApi';
import { Otovo$Locale } from '../../../types/otovoweb';
import { Sanity$Query } from '../../../types/sanityTypes';

export function getAllLocatorPagesQuery(
  path: string,
  buConfig: Cloud$BusinessUnitConfig,
  locale: Otovo$Locale | undefined,
): Sanity$Query {
  const localeQuery = getSanityLocaleDocumentQuery({ locale, buConfig });
  const { market } = buConfig;
  return {
    identifier: path,
    queryString: /* groq */ `*[_type == 'locatorPage' && activeFrom <= $now
    ${localeQuery}
    && references("market-${market}")] | order(priority asc, title asc){
      ...,
      mainImage{
        ...,
        asset->{
          ...,
          metadata
        }
      },
      _id,
      "slug": slug.current,
      "noindex": meta.noindex,
      'locale': __i18n_lang,
    }`,
  };
}
