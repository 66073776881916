import dynamic from 'next/dynamic';

import { LanguageSwitcherPathResolver } from '@otovo/shared/components/Navigation/types';
import classNames from 'classnames';
import { useGlobalConfig } from '../../hooks/configContext';
import { Sanity$Document } from '../../types/sanityTypes';
import BusinessUnitLink from '../BusinessUnitLink';

type NavbarProps = {
  children?: React.ReactNode;
  sanityDocument?: Sanity$Document;
  languageSwitcherPathResolver?: LanguageSwitcherPathResolver;
  navItems?: React.ReactNode;
};

const LanguageSwitcher = dynamic(() => import('./LanguageSwitcher'), {
  ssr: false,
});

const Navbar = ({
  children,
  sanityDocument,
  languageSwitcherPathResolver,
  navItems,
}: NavbarProps) => {
  const { BU_CONFIG } = useGlobalConfig();

  const isMultiLocaleBusinessUnit = BU_CONFIG.supported_locales.length > 1;

  const centerLogo = !navItems;

  return (
    <nav
      // Don't change this ID - useElementHeightById relies on it
      id="otovoNavBar"
      className="z-40 flex w-full items-center bg-white px-6 shadow md:px-8"
    >
      <div
        className={classNames(
          centerLogo
            ? 'flex-1 pb-3 pt-4 text-center'
            : 'flex-shrink-0 pb-4 pt-5',
        )}
      >
        <BusinessUnitLink
          className={classNames(
            'inline-block',
            !centerLogo && 'sm:mr-4 lg:mr-9',
            centerLogo && isMultiLocaleBusinessUnit && '-mr-16',
          )}
        />
      </div>
      <div className="hidden flex-nowrap space-x-2 truncate md:flex">
        {navItems}
      </div>
      <div className="ml-auto flex items-center">
        {isMultiLocaleBusinessUnit && (
          <div>
            <LanguageSwitcher
              sanityDocument={sanityDocument}
              pathResolver={languageSwitcherPathResolver}
            />
          </div>
        )}
        {children}
      </div>
    </nav>
  );
};

export default Navbar;
