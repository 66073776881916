import { Cloud$BusinessUnitConfig } from '../types/cloudApi';
import PoweredByOtovo from './PoweredByOtovo';

type LogoConfig = {
  logoSrc: string;
  style: {
    width?: string;
    height?: string;
    maxHeight?: string;
    minWidth?: string;
  };
  companyName: string;
  storefrontHref: string;
};

type LogoProps = {
  logoSrc: LogoConfig['logoSrc'];
  style: LogoConfig['style'];
  companyName: LogoConfig['companyName'];
};

const BusinessUnitLogo = ({ logoSrc, style, companyName }: LogoProps) => {
  const partnersWithPoweredByLogo = ['Mint', 'Labellenergie'];
  if (partnersWithPoweredByLogo.includes(companyName)) {
    // Temporary workaround - should be gone within a day or two. If not, blame @rix1
    return (
      <div className="flex items-baseline gap-5">
        <img src={logoSrc} alt={`${companyName} logo`} style={{ ...style }} />
        <span className="block h-2/3 border-l text-grey-30" />
        <PoweredByOtovo />
      </div>
    );
  }

  return <img src={logoSrc} alt={`${companyName} logo`} style={style} />;
};

// May otherwise cause a hydration error
function ensureTrailingSlash(path: string) {
  if (path.endsWith('/')) {
    return path;
  }

  return `${path}/`;
}

function getCompanyDefaults(
  buConfig: Cloud$BusinessUnitConfig,
  fallback: string,
) {
  const { matchedPartner } = buConfig;
  if (
    matchedPartner?.type === 'AFFILIATE' &&
    matchedPartner.config.theming_enabled
  ) {
    return {
      id: matchedPartner.config.utm_sources[0],
      companyName: matchedPartner.config.name,
      storefrontHref: fallback,
      logoSrc: matchedPartner.config.logo,
      style: {
        width: 'auto',
        height: '32px',
      },
    };
  }
  return {
    id: buConfig.slug,
    companyName: buConfig.company_name,
    storefrontHref: fallback,
    logoSrc: buConfig.logo,
    style: {
      width: 'auto',
      height: '32px',
    },
  };
}

export function getLogoOverrides(
  buConfig: Cloud$BusinessUnitConfig,
  fallback: string,
): LogoConfig {
  const companyDefaults = getCompanyDefaults(
    buConfig,
    ensureTrailingSlash(fallback),
  );

  switch (companyDefaults.id.toLowerCase()) {
    case 'oktavia-it':
    case 'leroymerlin-it':
    case 'fastweb-it':
      return {
        ...companyDefaults,
        storefrontHref: '#',
      };
    case 'meyerburger-at':
    case 'meyerburger-de':
    case 'meyerburger-ch':
      return {
        ...companyDefaults,
        style: {
          width: '400px',
          height: 'auto',
        },
        storefrontHref: `${buConfig.storefront_urls.production}/partner/meyer-burger`,
      };

    case 'imaginaenergia-es':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          maxHeight: '54px',
        },
      };
    case 'vivi-it':
      return {
        ...companyDefaults,
        storefrontHref: 'https://www.viviesco.it/',
      };
    case 'lucera-es':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          minWidth: '180px',
          maxHeight: '32px',
        },
      };

    case 'masmovil-es':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          minWidth: '180px',
          maxHeight: '32px',
        },
      };
    case 'mgc-it':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          minWidth: '80px',
          maxHeight: '60px',
        },
      };
    case 'yippie-de':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          minWidth: '100px',
          maxHeight: '54px',
        },
      };
    case 'segugio':
      return {
        ...companyDefaults,
        storefrontHref: `${buConfig.storefront_urls.production}/partner/segugio/`,
      };
    case 'fixo-pt':
      return {
        ...companyDefaults,
        storefrontHref: 'https://www.otovo.pt/partner/fixo-pt/',
      };
    default:
      return companyDefaults;
  }
}

export default BusinessUnitLogo;
