import Navbar from '@otovo/shared/components/Navigation/Navbar';
import {
  ProductPageMenuLinks,
  ProductPageNavLinks,
} from '@otovo/shared/components/Navigation/ProductPageLinks';
import { useNavbarLinks } from '../hooks/useNavbarLinks';

import NavSlideoverMenu from '@otovo/shared/components/Navigation/NavSlideoverMenu';
import { Sanity$Document } from '@otovo/shared/types/sanityTypes';
import { LanguageSwitcherPathResolver } from '@otovo/shared/components/Navigation/types';

type Props = {
  sanityDocument?: Sanity$Document;
  languageSwitcherPathResolver?: LanguageSwitcherPathResolver;
};

const StorefrontNav = ({
  sanityDocument,
  languageSwitcherPathResolver,
}: Props) => {
  return (
    <Navbar
      sanityDocument={sanityDocument}
      languageSwitcherPathResolver={languageSwitcherPathResolver}
      navItems={<ProductPageNavLinks includeDomainInUrls={false} />}
    >
      <NavSlideoverMenu useLinks={useNavbarLinks}>
        <ProductPageMenuLinks includeDomainInUrls={false} />
      </NavSlideoverMenu>
    </Navbar>
  );
};

export default StorefrontNav;
